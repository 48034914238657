/*
Author       : Dreamguys
Template Name: Kanakku - Bootstrap Admin Template
Version      : 1.0
*/

/*============================
 [Table of CSS]

1. General
2. Table
3. Helper Class
4. Bootstrap Classes
5. Modal
6. Select2
7. Nav Tabs
8. Components
9. Header
10. Chat
11. Search
12. Sidebar
13. Calendar
14. Inbox
15. Mail View
16. Error
17. Content
18. Login
19. Notifications
20. Dashboard
21. Invoices
22. Settings
23. Switch Buttons
24. Profile
25. Responsive

========================================*/

/*-----------------
  1. General
-----------------------*/

@font-face {
  font-family: 'CircularStd';

  font-style: normal;

  font-weight: 400;

  src: local('Circular Std Book'), url(/assets/fonts/CircularStd-Book.woff?e6a58ec19935a9f4b5ee64c95cfc8032) format('woff');
}

@font-face {
  font-family: 'CircularStd';

  font-style: italic;

  font-weight: 400;

  src: local('Circular Std Book Italic'), url(/assets/fonts/CircularStd-BookItalic.woff?65f3442f6abc56ba91c8dfd27244b179) format('woff');
}

@font-face {
  font-family: 'CircularStd';

  font-style: normal;

  font-weight: 500;

  src: local('Circular Std Medium'), url(/assets/fonts/CircularStd-Medium.woff?4f832c6dc49d3da923f434072b91c4b2) format('woff');
}

@font-face {
  font-family: 'CircularStd';

  font-style: italic;

  font-weight: 500;

  src: local('Circular Std Medium Italic'), url(/assets/fonts/CircularStd-MediumItalic.woff?b33b10b924f58eb9c83612ae8abd6530) format('woff');
}

@font-face {
  font-family: 'CircularStd';

  font-style: normal;

  font-weight: 600;

  src: local('Circular Std Bold'), url(/assets/fonts/CircularStd-Bold.woff?5287d011e228bc155134965a5e9dd52e) format('woff');
}

@font-face {
  font-family: 'CircularStd';

  font-style: italic;

  font-weight: 600;

  src: local('Circular Std Bold Italic'), url(/assets/fonts/CircularStd-BoldItalic.woff?8c413887dba6e8c5e9c77a251f43a14c) format('woff');
}

@font-face {
  font-family: 'CircularStd';

  font-style: normal;

  font-weight: 800;

  src: local('Circular Std Black'), url(/assets/fonts/CircularStd-Black.woff?47af1cb946470aab5b1f83f113318834) format('woff');
}

@font-face {
  font-family: 'CircularStd';

  font-style: italic;

  font-weight: 800;

  src: local('Circular Std Black Italic'), url(/assets/fonts/CircularStd-BlackItalic.woff?2402c0d8eb3c2560f1bd3dc128beee31) format('woff');
}

@font-face {
  font-family: 'Material Icons';

  font-style: normal;

  font-weight: 400;

  src: url(/assets/fonts/MaterialIcons-Regular.eot?4674f8ded773cb03e824323bfc950537);

  /* For IE6-8 */

  src: local('Material Icons'), local('MaterialIcons-Regular'), url(/assets/fonts/MaterialIcons-Regular.woff2?cff684e59ffb052d72cb8d5e49471553) format('woff2'), url(/fonts/MaterialIcons-Regular.woff?83bebaf37c09c7e1c3ee52682892ae14) format('woff'), url(/fonts/MaterialIcons-Regular.ttf?5e7382c63da0098d634a356ff441614e) format('truetype');
}

html {
  height: 100%;
}

body {
  background-color: #f7f8f9;
  color: #455560;
  font-family: 'CircularStd', sans-serif;
  font-size: 0.9375rem;
  height: 100%;
  line-height: 1.5;
  overflow-x: hidden;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: 'CircularStd', sans-serif;
  margin-top: 0;
  color: #2c3038;
}

a:hover,
a:active,
a:focus {
  outline: none;
  text-decoration: none;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus {
  -webkit-box-shadow: 0 0 0px 1000px white inset !important;
}

.form-control {
  border: 1px solid #dee2e6;
  box-shadow: none;
  color: #333;
  padding: .54688rem .875rem;
  font-size: .875rem;
  height: calc(1.6em + 1.21875rem);
  line-height: 1.6;
  font-weight: 400;
  color: #1e2022;
}

.form-control:focus {
  box-shadow: 0 0 10px rgba(136, 136, 136, 0.125);
  outline: 0 none;
  border-color: #c1c9d0;
}

.form-control-sm {
  height: calc(1.5em + 1rem);
  padding: .4375rem .65625rem;
  font-size: .8125rem;
  line-height: 1.5;
  border-radius: .25rem;
}

.form-control-lg {
  height: calc(1.5em + 1.875rem);
  padding: .875rem 1.09375rem;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: .3125rem;
}

a {
  color: #377dff;
}

a:hover {
  color: #0052ea;
  text-decoration: none;
}

p {
  margin-top: 0;
  margin-bottom: 1rem;
}

p:last-child {
  margin-bottom: 0;
}

dl, ol, ul {
  margin-top: 0;
  margin-bottom: 0;
}

input,
button,
a {
  transition: all 0.4s ease;
  -moz-transition: all 0.4s ease;
  -o-transition: all 0.4s ease;
  -transition: all 0.4s ease;
  -webkit-transition: all 0.4s ease;
}

input,
input:focus,
button,
button:focus {
  outline: none;
}

input[type="file"] {
  
  min-height: calc(1.5em + .75rem + 2px);
}

input[type=text],
input[type=password] {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

textarea.form-control {
  resize: vertical;
}

.navbar-nav > li {
  float: left;
}

.form-group {
  margin-bottom: 1.25rem;
}

.form-group img {
  width:100%;
}

.input-group .form-control {
  height: 40px;
}

.nav .open > a,
.nav .open > a:focus,
.nav .open > a:hover {
  background-color: rgba(0, 0, 0, 0.1);
  border-color: rgba(0, 0, 0, 0.1);
}

.material-icons {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 24px;
  display: inline-block;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizeLegibility;
  -moz-osx-font-smoothing: grayscale;
  font-feature-settings: 'liga';
}

ul li{
  list-style: none;
}

p:last-child{
  margin-bottom: 0;
}

/*-----------------
  2. Table
-----------------------*/

.table {
  color: #333;
  max-width: 100%;
  margin-bottom: 0;
  width: 100%;
}

.table-striped > tbody > tr:nth-of-type(2n+1) {
  background-color: #fff;
}
.table-striped > tbody > tr{
  background-color: #fff;
}
.table-striped > tbody > tr:hover{
  background-color: transparent;
}

.table.no-border > tbody > tr > td,
.table > tbody > tr > th,
.table.no-border > tfoot > tr > td,
.table.no-border > tfoot > tr > th,
.table.no-border > thead > tr > td,
.table.no-border > thead > tr > th {
  padding: 10px 8px;
}

.table-nowrap td,
.table-nowrap th {
  white-space: nowrap
}

.table.dataTable {
  border-collapse: collapse !important;
}

table.table td h2 {
  display: inline-block;
  font-size: inherit;
  font-weight: 400;
  margin: 0;
  padding: 0;
  vertical-align: middle;
}

table.table td h2.table-avatar {
  align-items: center;
  display: inline-flex;
  font-size: inherit;
  font-weight: 400;
  margin: 0;
  padding: 0;
  vertical-align: middle;
  white-space: nowrap;
}

table.table td h2 a {
  color: #333;
}

table.table td h2 a:hover {
  color: #7638ff;
}

table.table td h2 span {
  color: #888;
  display: block;
  font-size: 12px;
  margin-top: 3px;
}

.table thead {
  background-color: transparent;
  border-bottom: 0;
}

.table thead tr th {
  font-weight: 500;
}

.table.table-center td,
.table.table-center th {
  vertical-align: middle;
}

.table-hover tbody tr:hover {
  background-color: #f6f6f7;
}

.table td, .table th {
  vertical-align: middle;
  white-space: nowrap;
}
.table-bordered td, .table-bordered th {
    border: 0 !important;
}

.table-bordered {
  border: 1px solid rgba(0, 0, 0, 0.05) !important;
}

.table-bordered td:first-child{
  border-top-left-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
}

.table-bordered td:last-child{
  border-top-right-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
}

.table-bordered th,
.table-bordered td {
  border-color: rgba(0, 0, 0, 0.05);
}

.card-table .card-body {
  padding: 0;
}

.card-table .card-body .table > thead > tr > th {
  border-top: 0;
}

.card-table .card-body .table tr td:first-child,
.card-table .card-body .table tr th:first-child {
  padding-left: 1.5rem;
}

.card-table .card-body .table tr td:last-child,
.card-table .card-body .table tr th:last-child {
  padding-right: 1.5rem;
}

.card-table .table td,
.card-table .table th {
  border-top: 1px solid #e2e5e8;
  padding: 1rem 0.75rem;
  white-space: nowrap;
}

/*-----------------
  3. Helper Class
-----------------------*/

.block {
  display: block !important;
}

.bullets li {
  list-style: inherit;
}

#toggle_btn i {
  font-size: 22px;
}

.bg-1 {
  background-color: #ffeec3;
}

.bg-1 i {
  color: #ffb800;
}

.bg-2 {
  background-color: #d1f3ff;
}

.bg-2 i {
  color: #42cdff;
}

.bg-3 {
  background-color: #ceffd5;
}

.bg-3 i {
  color: #52fb6a;
}

.bg-4 {
  background-color: #fccaff;
}

.bg-4 i {
  color: #f555ff;
}

.bg-5 {
  background-color: #ffb800;
}

.bg-6 {
  background-color: #42cdff;
}

.bg-7 {
  background-color: #52fb6a;
}

.bg-8 {
  background-color: #f555ff;
}

.table .btn {
  white-space: nowrap;
}

table .badge {
  border-radius: 3px;
  display: inline-block;
  font-size: 13px;
  min-width: 75px;
  padding: 4px 12px;
  text-align: center;
}

.feather {
  width: 18px;
  height: 18px;
}

/*-----------------
  4. Bootstrap Classes
-----------------------*/

.btn.focus,
.btn:focus {
  box-shadow: unset;
}

.btn-white {
  background-color: #fff;
  border-color: #e7eaf3;
}

.btn.btn-rounded {
  border-radius: 50px;
}

.bg-primary,
.badge-primary {
  background-color: #7638ff !important;
}

a.bg-primary:focus,
a.bg-primary:hover,
button.bg-primary:focus,
button.bg-primary:hover {
  background-color: #621aff !important;
}

.bg-success,
.badge-success {
  background-color: #22cc62 !important;
}

a.bg-success:focus,
a.bg-success:hover,
button.bg-success:focus,
button.bg-success:hover {
  background-color: #1eae55 !important;
}

.bg-info,
.badge-info {
  background-color: #009efb !important;
}

a.bg-info:focus,
a.bg-info:hover,
button.bg-info:focus,
button.bg-info:hover {
  background-color: #028ee1 !important;
}

.bg-warning,
.badge-warning {
  background-color: #ffbc34 !important;
}

a.bg-warning:focus,
a.bg-warning:hover,
button.bg-warning:focus,
button.bg-warning:hover {
  background-color: #e9ab2e !important;
}

.bg-danger,
.badge-danger {
  background-color: #ef3737 !important;
}

a.bg-danger:focus,
a.bg-danger:hover,
button.bg-danger:focus,
button.bg-danger:hover {
  background-color: #e63333 !important;
}

.bg-white {
  background-color: #fff;
}

.bg-purple,
.badge-purple {
  background-color: #9368e9 !important;
}

.text-primary,
.dropdown-menu > li > a.text-primary {
  color: #7638ff !important;
}

.text-success,
.dropdown-menu > li > a.text-success {
  color: #22cc62 !important;
}

.text-danger,
.dropdown-menu > li > a.text-danger {
  color: #ef3737 !important;
}

.text-info,
.dropdown-menu > li > a.text-info {
  color: #009efb !important;
}

.text-warning,
.dropdown-menu > li > a.text-warning {
  color: #ffbc34 !important;
}

.text-purple,
.dropdown-menu > li > a.text-purple {
  color: #7460ee !important;
}

.text-muted {
  color: #757575 !important;
}

.btn-primary {
  background-color: #7638ff;
  border: 1px solid #7638ff;
}

.btn-primary:hover,
.btn-primary:focus,
.btn-primary.active,
.btn-primary:active,
.open > .dropdown-toggle.btn-primary {
  background-color: #621aff;
  border: 1px solid #621aff;
}

.btn-primary.active.focus,
.btn-primary.active:focus,
.btn-primary.active:hover,
.btn-primary.focus:active,
.btn-primary:active:focus,
.btn-primary:active:hover,
.open > .dropdown-toggle.btn-primary.focus,
.open > .dropdown-toggle.btn-primary:focus,
.open > .dropdown-toggle.btn-primary:hover {
  background-color: #621aff;
  border: 1px solid #621aff;
}

.btn-primary.active:not(:disabled):not(.disabled),
.btn-primary:active:not(:disabled):not(.disabled),
.show > .btn-primary.dropdown-toggle {
  background-color: #621aff;
  border-color: #621aff;
  color: #fff;
}

.btn-primary.active:focus:not(:disabled):not(.disabled),
.btn-primary:active:focus:not(:disabled):not(.disabled),
.show > .btn-primary.dropdown-toggle:focus {
  box-shadow: unset;
}

.btn-primary.disabled,
.btn-primary:disabled {
  background-color: #7638ff;
  border-color: #7638ff;
  color: #fff;
}

.btn-secondary.active:focus:not(:disabled):not(.disabled),
.btn-secondary:active:focus:not(:disabled):not(.disabled),
.show > .btn-secondary.dropdown-toggle:focus {
  box-shadow: unset;
}

.btn-success {
  background-color: #22cc62;
  border: 1px solid #22cc62
}

.btn-success:hover,
.btn-success:focus,
.btn-success.active,
.btn-success:active,
.open > .dropdown-toggle.btn-success {
  background-color: #1eae55;
  border: 1px solid #1eae55;
  color: #fff;
}

.btn-success.active.focus,
.btn-success.active:focus,
.btn-success.active:hover,
.btn-success.focus:active,
.btn-success:active:focus,
.btn-success:active:hover,
.open > .dropdown-toggle.btn-success.focus,
.open > .dropdown-toggle.btn-success:focus,
.open > .dropdown-toggle.btn-success:hover {
  background-color: #1eae55;
  border: 1px solid #1eae55
}

.btn-success.active:not(:disabled):not(.disabled),
.btn-success:active:not(:disabled):not(.disabled),
.show > .btn-success.dropdown-toggle {
  background-color: #1eae55;
  border-color: #1eae55;
  color: #fff;
}

.btn-success.active:focus:not(:disabled):not(.disabled),
.btn-success:active:focus:not(:disabled):not(.disabled),
.show > .btn-success.dropdown-toggle:focus {
  box-shadow: unset;
}

.btn-success.disabled,
.btn-success:disabled {
  background-color: #22cc62;
  border-color: #22cc62;
  color: #fff;
}

.btn-info {
  background-color: #009efb;
  border: 1px solid #009efb
}

.btn-info:hover,
.btn-info:focus,
.btn-info.active,
.btn-info:active,
.open > .dropdown-toggle.btn-info {
  background-color: #028ee1;
  border: 1px solid #028ee1
}

.btn-info.active.focus,
.btn-info.active:focus,
.btn-info.active:hover,
.btn-info.focus:active,
.btn-info:active:focus,
.btn-info:active:hover,
.open > .dropdown-toggle.btn-info.focus,
.open > .dropdown-toggle.btn-info:focus,
.open > .dropdown-toggle.btn-info:hover {
  background-color: #028ee1;
  border: 1px solid #028ee1
}

.btn-info.active:not(:disabled):not(.disabled),
.btn-info:active:not(:disabled):not(.disabled),
.show > .btn-info.dropdown-toggle {
  background-color: #028ee1;
  border-color: #028ee1;
  color: #fff;
}

.btn-info.active:focus:not(:disabled):not(.disabled),
.btn-info:active:focus:not(:disabled):not(.disabled),
.show > .btn-info.dropdown-toggle:focus {
  box-shadow: unset;
}

.btn-info.disabled,
.btn-info:disabled {
  background-color: #009efb;
  border-color: #009efb;
  color: #fff;
}

.btn-warning {
  background-color: #ffbc34;
  border: 1px solid #ffbc34
}

.btn-warning:hover,
.btn-warning:focus,
.btn-warning.active,
.btn-warning:active,
.open > .dropdown-toggle.btn-warning {
  background-color: #e9ab2e;
  border: 1px solid #e9ab2e
}

.btn-warning.active.focus,
.btn-warning.active:focus,
.btn-warning.active:hover,
.btn-warning.focus:active,
.btn-warning:active:focus,
.btn-warning:active:hover,
.open > .dropdown-toggle.btn-warning.focus,
.open > .dropdown-toggle.btn-warning:focus,
.open > .dropdown-toggle.btn-warning:hover {
  background-color: #e9ab2e;
  border: 1px solid #e9ab2e
}

.btn-warning.active:not(:disabled):not(.disabled),
.btn-warning:active:not(:disabled):not(.disabled),
.show > .btn-danger.dropdown-toggle {
  background-color: #e9ab2e;
  border-color: #e9ab2e;
  color: #fff;
}

.btn-warning.active:focus:not(:disabled):not(.disabled),
.btn-warning:active:focus:not(:disabled):not(.disabled),
.show > .btn-warning.dropdown-toggle:focus {
  box-shadow: unset;
}

.btn-warning.disabled,
.btn-warning:disabled {
  background-color: #ffbc34;
  border-color: #ffbc34;
  color: #fff;
}

.badge-secondary {
  background-color: #621aff !important;
}

.btn-danger {
  background-color: #ef3737;
  border: 1px solid #ef3737;
}

.btn-danger:hover,
.btn-danger:focus,
.btn-danger.active,
.btn-danger:active,
.open > .dropdown-toggle.btn-danger {
  background-color: #ec1313;
  border: 1px solid #ec1313;
}

.btn-danger.active.focus,
.btn-danger.active:focus,
.btn-danger.active:hover,
.btn-danger.focus:active,
.btn-danger:active:focus,
.btn-danger:active:hover,
.open > .dropdown-toggle.btn-danger.focus,
.open > .dropdown-toggle.btn-danger:focus,
.open > .dropdown-toggle.btn-danger:hover {
  background-color: #ec1313;
  border: 1px solid #ec1313;
}

.btn-danger.active:not(:disabled):not(.disabled),
.btn-danger:active:not(:disabled):not(.disabled),
.show > .btn-danger.dropdown-toggle {
  background-color: #ec1313;
  border-color: #ec1313;
  color: #fff;
}

.btn-danger.active:focus:not(:disabled):not(.disabled),
.btn-danger:active:focus:not(:disabled):not(.disabled),
.show > .btn-danger.dropdown-toggle:focus {
  box-shadow: unset;
}

.btn-danger.disabled,
.btn-danger:disabled {
  background-color: #f62d51;
  border-color: #f62d51;
  color: #fff;
}

.btn-light.active:focus:not(:disabled):not(.disabled),
.btn-light:active:focus:not(:disabled):not(.disabled),
.show > .btn-light.dropdown-toggle:focus {
  box-shadow: unset;
}

.btn-dark.active:focus:not(:disabled):not(.disabled),
.btn-dark:active:focus:not(:disabled):not(.disabled),
.show > .btn-dark.dropdown-toggle:focus {
  box-shadow: unset;
}

.btn-outline-primary {
  color: #7638ff;
  border-color: #7638ff;
}

.btn-outline-primary:hover {
  background-color: #7638ff;
  border-color: #7638ff;
}

.btn-outline-primary:focus,
.btn-outline-primary.focus {
  box-shadow: none;
}

.btn-outline-primary.disabled,
.btn-outline-primary:disabled {
  color: #7638ff;
  background-color: transparent;
}

.btn-outline-primary:not(:disabled):not(.disabled):active,
.btn-outline-primary:not(:disabled):not(.disabled).active,
.show > .btn-outline-primary.dropdown-toggle {
  background-color: #7638ff;
  border-color: #7638ff;
}

.btn-outline-primary:not(:disabled):not(.disabled):active:focus,
.btn-outline-primary:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-primary.dropdown-toggle:focus {
  box-shadow: none;
}

.btn-outline-success {
  color: #22cc62;
  border-color: #22cc62;
}

.btn-outline-success:hover {
  background-color: #22cc62;
  border-color: #22cc62;
}

.btn-outline-success:focus,
.btn-outline-success.focus {
  box-shadow: none;
}

.btn-outline-success.disabled,
.btn-outline-success:disabled {
  color: #22cc62;
  background-color: transparent;
}

.btn-outline-success:not(:disabled):not(.disabled):active,
.btn-outline-success:not(:disabled):not(.disabled).active,
.show > .btn-outline-success.dropdown-toggle {
  background-color: #22cc62;
  border-color: #22cc62;
}

.btn-outline-success:not(:disabled):not(.disabled):active:focus,
.btn-outline-success:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-success.dropdown-toggle:focus {
  box-shadow: none;
}

.btn-outline-info {
  color: #00bcd4;
  border-color: #00bcd4;
}

.btn-outline-info:hover {
  color: #fff;
  background-color: #00bcd4;
  border-color: #00bcd4;
}

.btn-outline-info:focus,
.btn-outline-info.focus {
  box-shadow: none;
}

.btn-outline-info.disabled,
.btn-outline-info:disabled {
  background-color: transparent;
  color: #00bcd4;
}

.btn-outline-info:not(:disabled):not(.disabled):active,
.btn-outline-info:not(:disabled):not(.disabled).active,
.show > .btn-outline-info.dropdown-toggle {
  background-color: #00bcd4;
  border-color: #00bcd4;
}

.btn-outline-info:not(:disabled):not(.disabled):active:focus,
.btn-outline-info:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-info.dropdown-toggle:focus {
  box-shadow: none;
}

.btn-outline-warning {
  color: #ffbc34;
  border-color: #ffbc34;
}

.btn-outline-warning:hover {
  color: #212529;
  background-color: #ffbc34;
  border-color: #ffbc34;
}

.btn-outline-warning:focus,
.btn-outline-warning.focus {
  box-shadow: none;
}

.btn-outline-warning.disabled,
.btn-outline-warning:disabled {
  background-color: transparent;
  color: #ffbc34;
}

.btn-outline-warning:not(:disabled):not(.disabled):active,
.btn-outline-warning:not(:disabled):not(.disabled).active,
.show > .btn-outline-warning.dropdown-toggle {
  color: #212529;
  background-color: #ffbc34;
  border-color: #ffbc34;
}

.btn-outline-warning:not(:disabled):not(.disabled):active:focus,
.btn-outline-warning:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-warning.dropdown-toggle:focus {
  box-shadow: none;
}

.btn-outline-danger {
  color: #ef3737;
  border-color: #ef3737;
}

.btn-outline-danger:hover {
  color: #fff;
  background-color: #ef3737;
  border-color: #ef3737;
}

.btn-outline-danger:focus,
.btn-outline-danger.focus {
  box-shadow: none;
}

.btn-outline-danger.disabled,
.btn-outline-danger:disabled {
  background-color: transparent;
  color: #ef3737;
}

.btn-outline-danger:not(:disabled):not(.disabled):active,
.btn-outline-danger:not(:disabled):not(.disabled).active,
.show > .btn-outline-danger.dropdown-toggle {
  background-color: #ef3737;
  border-color: #ef3737;
}

.btn-outline-danger:not(:disabled):not(.disabled):active:focus,
.btn-outline-danger:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-danger.dropdown-toggle:focus {
  box-shadow: none;
}

.btn-outline-light {
  color: #ababab;
  border-color: #e6e6e6;
}

.btn-outline-light.disabled,
.btn-outline-light:disabled {
  color: #ababab;
}

.pagination > .active > a,
.pagination > .active > a:focus,
.pagination > .active > a:hover,
.pagination > .active > span,
.pagination > .active > span:focus,
.pagination > .active > span:hover {
  background-color: #7638ff;
  border-color: #7638ff;
}

.pagination > li > a,
.pagination > li > span {
  color: #7638ff;
}

.page-link:hover {
  color: #7638ff;
}

.page-link:focus {
  box-shadow: unset;
}
.double_icon svg {
    width: 16px;
    height: 16px;
    margin-right: 2px;
    color: rgba(160,174,192);
}
.page-item.active .page-link {
  background-color: #7638ff;
  border-color: #7638ff;
}
.text-grey {
  color: rgba(160,174,192);
  padding-bottom: 12px;
}
.dropdown-menu {
  border: 1px solid #eff2f7;
    border-radius: .8rem;
    transform-origin: left top 0;
    background-color: #fff;
    box-shadow: 0 0 1.25rem rgb(31 45 61 / 8%);
    /*height: 100px;
    overflow: auto;*/
}

.navbar-nav .open .dropdown-menu {
  border: 0;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  background-color: #fff;
}

.dropdown-menu {
  font-size: 14px;
}

.card {
  background: #fff;
    -webkit-box-shadow: 0 0 13px 0 rgb(82 63 105 / 5%);
    box-shadow: 0 0 13px 0 rgb(82 63 105 / 5%) !important;
    margin-bottom: 30px;
    border-radius: 20px;
    border: 0;
}

.card-body {
  position: relative;
  padding: 1.5rem;
}
.custom_Card .card {
  background: transparent !important;
}
.table-responsive {
    border-radius: 20px;
}
.card .card-header {
  border-color: #f0f1f5;
  background-color: #fff;
  padding: 1.5rem 1.875rem 1.25rem;
}

.card-header:first-child {
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
}

.card-footer:last-child {
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
}

.card-footer {
  background-color: #fff;
  border-top: 1px solid #e6e6e6;
  padding: 1rem;
}

.table .thead-light th {
  color: #495057;
  background-color: #f8f9fa;
  border-color: #eff2f7;
}

.card .card-header .card-title {
  margin-bottom: 0;
}

.modal-footer.text-left {
  text-align: left;
}

.modal-footer.text-center {
  text-align: center;
}

.btn-light {
  border-color: #e6e6e6;
  color: #a6a6a6;
}

.bootstrap-datetimepicker-widget table td.active,
.bootstrap-datetimepicker-widget table td.active:hover {
  background-color: #7638ff;
  text-shadow: unset;
}

.bootstrap-datetimepicker-widget table td.today:before {
  border-bottom-color: #7638ff;
}

.bg-info-light {
  background-color: rgba(2, 182, 179, 0.12) !important;
  color: #1db9aa !important;
}

.bg-primary-light {
  background-color: rgba(17, 148, 247, 0.12) !important;
  color: #2196f3 !important;
}

.bg-danger-light {
  background-color: rgba(242, 17, 54, 0.12) !important;
  color: #e63c3c !important;
}

.bg-warning-light {
  background-color: rgba(255, 152, 0, 0.12) !important;
  color: #f39c12 !important;
}

.bg-success-light {
  background-color: rgba(15, 183, 107, 0.12) !important;
  color: #26af48 !important;
}

.bg-purple-light {
  background-color: rgba(197, 128, 255, 0.12) !important;
  color: #c580ff !important;
}

.bg-default-light {
  background-color: rgba(40, 52, 71, 0.12) !important;
  color: #283447 !important;
}

/*-----------------
  5. Modal
-----------------------*/

.modal {
  -webkit-overflow-scrolling: touch;
}

.modal-footer.text-center {
  justify-content: center;
}

.modal-footer.text-left {
  justify-content: flex-start;
}

.modal-dialog.modal-md {
  max-width: 600px;
}

.custom-modal .modal-content {
  border: 0;
  border-radius: 10px;
}

.custom-modal .modal-footer {
  border: 0;
}

.custom-modal .modal-header {
  border: 0;
  justify-content: center;
  padding: 30px 30px 0;
}

.custom-modal .modal-footer {
  border: 0;
  justify-content: center;
  padding: 0 30px 30px;
}

.custom-modal .modal-body {
  padding: 30px;
}

.custom-modal .close {
  background-color: #a0a0a0;
  border-radius: 50%;
  color: #fff;
  font-size: 13px;
  height: 20px;
  line-height: 20px;
  margin: 0;
  opacity: 1;
  padding: 0;
  position: absolute;
  right: 10px;
  top: 10px;
  width: 20px;
  z-index: 99;
}

.custom-modal .modal-title {
  font-size: 22px;
}

.modal-backdrop.show {
  opacity: 0.4;
  transition-duration: 400ms;
}

.modal .card {
  box-shadow: unset;
}

/*-----------------
  6. Select2
-----------------------*/

.select2-container .select2-selection--single {
  border: 1px solid #ddd;
  height: 40px;
}

.select2-container--default .select2-selection--single .select2-selection__arrow {
  height: 38px;
  right: 7px;
}

.select2-container--default .select2-selection--single .select2-selection__arrow b {
  border-color: #ddd transparent transparent;
  border-style: solid;
  border-width: 6px 6px 0;
  height: 0;
  left: 50%;
  margin-left: -10px;
  margin-top: -2px;
  position: absolute;
  top: 50%;
  width: 0;
}

.select2-container--default.select2-container--open .select2-selection--single .select2-selection__arrow b {
  border-color: transparent transparent #ddd;
  border-width: 0 6px 6px;
}

.select2-container .select2-selection--single .select2-selection__rendered {
  padding-right: 30px;
  padding-left: 15px;
}

.select2-container--default .select2-selection--single .select2-selection__rendered {
  color: #333;
  font-size: 15px;
  font-weight: normal;
  line-height: 38px;
}

.select2-container--default .select2-results__option--highlighted[aria-selected] {
  background-color: #7638ff;
}

.select2-container--default .select2-selection--multiple {
  border: 1px solid #ddd;
  min-height: 40px;
}

/*-----------------
  7. Nav Tabs
-----------------------*/

.nav-tabs {
  border-bottom: none;
}

.card-header-tabs {
  border-bottom: 0;
}

.nav-tabs > li > a {
  margin-right: 0;
  color: #888;
  border-radius: 0;
}

.nav-tabs > li > a:hover,
.nav-tabs > li > a:focus {
  border-color: transparent;
  color: #333;
}

.nav-tabs.nav-tabs-solid > li > a {
  color: #333;
}

.nav-tabs.nav-tabs-solid > .active > a,
.nav-tabs.nav-tabs-solid > .active > a:hover,
.nav-tabs.nav-tabs-solid > .active > a:focus {
  background-color: #7638ff;
  border-color: #7638ff;
  color: #fff;
}

.tab-content {
  padding-top: 20px;
}

.nav-tabs .nav-link {
  border-radius: 0;
}

.nav-tabs .nav-link:focus,
.nav-tabs .nav-link:hover {
  background-color: #7638ff;
    border-color: transparent;
    color: #fff;
}
.nav-tabs .nav-item.show .nav-link, .nav-tabs .nav-link.active {
    background-color: #7638ff;
    border-color: transparent;
    color: #fff;
}
.nav-tabs.nav-justified > li > a {
  border-radius: 0;
  margin-bottom: 0;
}

.nav-tabs.nav-justified > li > a:hover,
.nav-tabs.nav-justified > li > a:focus {
  border-bottom-color: #ddd;
}

.nav-tabs.nav-justified.nav-tabs-solid > li > a {
  border-color: transparent;
}

.nav-tabs.nav-tabs-solid > li > a {
  color: #333;
  padding: 10px 30px;
}

.nav-tabs.nav-tabs-solid > li > a.active,
.nav-tabs.nav-tabs-solid > li > a.active:hover,
.nav-tabs.nav-tabs-solid > li > a.active:focus {
  background-color: #7638ff;
  border-color: #7638ff;
  color: #fff;
  padding: 10px 30px;
}

.nav-tabs.nav-tabs-solid.nav-tabs-rounded {
  border-radius: 50px;
}

.nav-tabs.nav-tabs-solid.nav-tabs-rounded > li > a {
  border-radius: 50px;
}

.nav-tabs.nav-tabs-solid.nav-tabs-rounded > li > a.active,
.nav-tabs.nav-tabs-solid.nav-tabs-rounded > li > a.active:hover,
.nav-tabs.nav-tabs-solid.nav-tabs-rounded > li > a.active:focus {
  border-radius: 50px;
}

.nav-tabs-justified > li > a {
  border-radius: 0;
  margin-bottom: 0;
}

.nav-tabs-justified > li > a:hover,
.nav-tabs-justified > li > a:focus {
  border-bottom-color: #ddd;
}

.nav-tabs-justified.nav-tabs-solid > li > a {
  border-color: transparent;
}

.nav-tabs.nav-justified.nav-tabs-top {
  border-bottom: 1px solid #ddd;
}

.nav-tabs.nav-justified.nav-tabs-top > li > a,
.nav-tabs.nav-justified.nav-tabs-top > li > a:hover,
.nav-tabs.nav-justified.nav-tabs-top > li > a:focus {
  border-width: 2px 0 0 0;
}

.nav-tabs.nav-tabs-top > li {
  margin-bottom: 0;
}

.nav-tabs.nav-tabs-top > li > a,
.nav-tabs.nav-tabs-top > li > a:hover,
.nav-tabs.nav-tabs-top > li > a:focus {
  border-width: 2px 0 0 0;
}

.nav-tabs.nav-tabs-top > li.open > a,
.nav-tabs.nav-tabs-top > li > a:hover,
.nav-tabs.nav-tabs-top > li > a:focus {
  border-top-color: #ddd;
}

.nav-tabs.nav-tabs-top > li+li > a {
  margin-left: 1px;
}

.nav-tabs.nav-tabs-top > li > a.active,
.nav-tabs.nav-tabs-top > li > a.active:hover,
.nav-tabs.nav-tabs-top > li > a.active:focus {
  border-top-color: #7638ff;
}

.nav-tabs.nav-tabs-bottom > li {
  margin-bottom: -1px;
}

.nav-tabs.nav-tabs-bottom > li > a.active,
.nav-tabs.nav-tabs-bottom > li > a.active:hover,
.nav-tabs.nav-tabs-bottom > li > a.active:focus {
  border-bottom-width: 2px;
  border-color: transparent;
  border-bottom-color: #7638ff;
  background-color: transparent;
  transition: none 0s ease 0s;
  -moz-transition: none 0s ease 0s;
  -o-transition: none 0s ease 0s;
  -transition: none 0s ease 0s;
  -webkit-transition: none 0s ease 0s;
}

.nav-tabs.nav-tabs-solid {
  background-color: #fafafa;
  border: 0;
}

.nav-tabs.nav-tabs-solid > li {
  margin-bottom: 0;
}

.nav-tabs.nav-tabs-solid > li > a {
  border-color: transparent;
}

.nav-tabs.nav-tabs-solid > li > a:hover,
.nav-tabs.nav-tabs-solid > li > a:focus {
  background-color: #f5f5f5;
}

.nav-tabs.nav-tabs-solid > .open:not(.active) > a {
  background-color: #f5f5f5;
  border-color: transparent;
}

.nav-tabs-justified.nav-tabs-top {
  border-bottom: 1px solid #ddd;
}

.nav-tabs-justified.nav-tabs-top > li > a,
.nav-tabs-justified.nav-tabs-top > li > a:hover,
.nav-tabs-justified.nav-tabs-top > li > a:focus {
  border-width: 2px 0 0 0;
}

/*-----------------
  8. Components
-----------------------*/

.section-header {
  margin-bottom: 1.875rem;
}

.section-header .section-title {
  color: #333;
}

.line {
  background-color: #7638ff;
  height: 2px;
  margin: 0;
  width: 60px;
}

.comp-buttons .btn {
  margin-bottom: 5px;
}

.pagination-box .pagination {
  margin-top: 0;
}

.comp-dropdowns .btn-group {
  margin-bottom: 5px;
}

.progress-example .progress {
  margin-bottom: 1.5rem;
}

.progress-xs {
  height: 4px;
}

.progress-sm {
  height: 15px;
}

.progress.progress-sm {
  height: 6px;
}

.progress.progress-md {
  height: 8px;
}

.progress.progress-lg {
  height: 18px;
}

.row.row-sm {
  margin-left: -3px;
  margin-right: -3px;
}

.row.row-sm > div {
  padding-left: 3px;
  padding-right: 3px;
}

.avatar {
  position: relative;
  display: inline-block;
  width: 3rem;
  height: 3rem
}

.avatar > img {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
}

.avatar-title {
  width: 100%;
  height: 100%;
  background-color: #7638ff;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
}

.avatar-away:before,
.avatar-offline:before,
.avatar-online:before {
  position: absolute;
  right: 0;
  bottom: 0;
  width: 25%;
  height: 25%;
  border-radius: 50%;
  content: '';
  border: 2px solid #fff;
}

.avatar-online:before {
  background-color: #22cc62;
}

.avatar-offline:before {
  background-color: #ef3737;
}

.avatar-away:before {
  background-color: #ffbc34;
}

.avatar .border {
  border-width: 3px !important;
}

.avatar .rounded {
  border-radius: 6px !important;
}

.avatar .avatar-title {
  font-size: 18px;
}

.avatar-xs {
  width: 1.65rem;
  height: 1.65rem;
}

.avatar-xs .border {
  border-width: 2px !important;
}

.avatar-xs .rounded {
  border-radius: 4px !important;
}

.avatar-xs .avatar-title {
  font-size: 12px;
}

.avatar-xs.avatar-away:before,
.avatar-xs.avatar-offline:before,
.avatar-xs.avatar-online:before {
  border-width: 1px;
}

.avatar-sm {
  width: 2.5rem;
  height: 2.5rem;
}

.avatar-sm .border {
  border-width: 3px !important;
}

.avatar-sm .rounded {
  border-radius: 4px !important;
}

.avatar-sm .avatar-title {
  font-size: 15px;
}

.avatar-sm.avatar-away:before,
.avatar-sm.avatar-offline:before,
.avatar-sm.avatar-online:before {
  border-width: 2px;
}

.avatar-lg {
  width: 3.75rem;
  height: 3.75rem;
}

.avatar-lg .border {
  border-width: 3px !important;
}

.avatar-lg .rounded {
  border-radius: 8px !important;
}

.avatar-lg .avatar-title {
  font-size: 24px;
}

.avatar-lg.avatar-away:before,
.avatar-lg.avatar-offline:before,
.avatar-lg.avatar-online:before {
  border-width: 3px;
}

.avatar-xl {
  width: 5rem;
  height: 5rem;
}

.avatar-xl .border {
  border-width: 4px !important;
}

.avatar-xl .rounded {
  border-radius: 8px !important;
}

.avatar-xl .avatar-title {
  font-size: 28px;
}

.avatar-xl.avatar-away:before,
.avatar-xl.avatar-offline:before,
.avatar-xl.avatar-online:before {
  border-width: 4px;
}

.avatar-xxl {
  width: 5.125rem;
  height: 5.125rem;
}

.avatar-xxl .border {
  border-width: 6px !important;
}

.avatar-xxl .rounded {
  border-radius: 8px !important;
}

.avatar-xxl .avatar-title {
  font-size: 30px;
}

.avatar-xxl.avatar-away:before,
.avatar-xxl.avatar-offline:before,
.avatar-xxl.avatar-online:before {
  border-width: 4px;
}

.avatar-group {
  display: inline-flex;
}

.avatar-group .avatar + .avatar {
  margin-left: -.75rem;
}

.avatar-group .avatar-xs + .avatar-xs {
  margin-left: -.40625rem;
}

.avatar-group .avatar-sm+.avatar-sm {
  margin-left: -.625rem;
}

.avatar-group .avatar-lg + .avatar-lg {
  margin-left: -1rem;
}

.avatar-group .avatar-xl + .avatar-xl {
  margin-left: -1.28125rem;
}

.avatar-group .avatar:hover {
  z-index: 1;
}

/*-----------------
  9. Header
-----------------------*/

.header {
  background: #fff;
  box-shadow: 0 0 13px 0 rgba(82,63,105,0.05);
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 1;
  height: 60px;
  border-radius: 0 0 20px 20px;
}

.header .header-left {
  float: left;
  height: 60px;
  padding: 0 20px;
  position: relative;
  text-align: center;
  width: 240px;
  z-index: 1;
  transition: all 0.2s ease-in-out;
}

.header .header-left .logo {
  display: inline-block;
  line-height: 60px;
}

.header .header-left .logo img {
  max-height: 45px;
  width: auto;
}

.header-left .logo.logo-small {
  display: none;
}

.header .dropdown-menu > li > a {
  position: relative;
}

.header .dropdown-toggle:after {
  display: none;
}

.header .has-arrow .dropdown-toggle:after {
  border-top: 0;
  border-left: 0;
  border-bottom: 2px solid #333;
  border-right: 2px solid #333;
  content: '';
  height: 8px;
  display: inline-block;
  pointer-events: none;
  -webkit-transform-origin: 66% 66%;
  -transform-origin: 66% 66%;
  transform-origin: 66% 66%;
  -webkit-transform: rotate(45deg);
  -transform: rotate(45deg);
  transform: rotate(45deg);
  transition: all 0.15s ease-in-out;
  width: 8px;
  vertical-align: 2px;
}

.header .has-arrow .dropdown-toggle[aria-expanded="true"]:after {
  -webkit-transform: rotate(-135deg);
  -transform: rotate(-135deg);
  transform: rotate(-135deg);
}

.user-menu {
  float: right;
  margin: 0;
  position: relative;
  z-index: 99;
  display: flex !important;
  padding-right: 20px;
}

.user-menu.nav > li > a {
  color: #333;
  font-size: 14px;
  line-height: 58px;
  padding: 0 15px;
  height: 60px;
}

.user-menu.nav > li > a:hover i,
.user-menu.nav > li > a:focus i {
  opacity: .8;
}

.user-img {
  display: inline-block;
  margin-right: 3px;
  position: relative;
}

.user-img img{
  width: 32px;
  border-radius: 50%;
}

.dropdown-item img{
  margin-right: 5px;
}

.user-menu.nav > li > a.mobile_btn {
  border: 0;
  position: relative;
  padding: 0;
  margin: 0;
  cursor: pointer
}

.user-menu .dropdown-menu {
  min-width: 200px;
  padding: 0;
}

.user-menu .dropdown-menu .dropdown-item {
  display: flex;
  align-items: center;
  padding: 10px 15px;
}

.user-menu .dropdown-menu .dropdown-item:hover {
  color: #7638ff;
  background-color: transparent;
}

.header .dropdown-menu > li > a:focus,
.header .dropdown-menu > li > a:hover {
  background-color: #7638ff;
  color: #fff;
}

.header .dropdown-menu > li > a:focus i,
.header .dropdown-menu > li > a:hover i {
  color: #fff;
}

.header .dropdown-menu > li > a {
  padding: 10px 18px;
}

.header .dropdown-menu > li > a i {
  color: #7638ff;
  margin-right: 10px;
  text-align: center;
  width: 18px;
}

.header .user-menu .dropdown-menu > li > a i {
  color: #7638ff;
  font-size: 16px;
  margin-right: 10px;
  min-width: 18px;
  text-align: center;
}

.header .user-menu .dropdown-menu > li > a:focus i,
.header .user-menu .dropdown-menu > li > a:hover i {
  color: #fff;
}

.mobile_btn {
  display: none;
  float: left;
}

.slide-nav .sidebar {
  margin-left: 0;
}

.menu-title {
  color: #a3a3a3;
  display: block;
  font-size: 14px;
  margin-bottom: 5px;
  padding: 0 25px;
}

.sidebar-overlay {
  background-color: rgba(0, 0, 0, 0.6);
  display: none;
  height: 100%;
  left: 0;
  position: fixed;
  top: 60px;
  width: 100%;
  z-index: 1000;
}

.sidebar-overlay.opened {
  display: block;
}

html.menu-opened {
  overflow: hidden;
}

html.menu-opened body {
  overflow: hidden;
}

/*-----------------
  10. Chat
-----------------------*/

.chat-window {
  display: flex;
  flex-wrap: wrap;
  position: relative;
  margin-bottom: 1.875rem;
  box-shadow: 0 0 13px 0 rgba(82,63,105,0.05);
  border-radius: 20px;
  overflow: hidden;
}

.chat-window .chat-cont-left {
  border-right: 1px solid #e5e5e5;
  flex: 0 0 35%;
  left: 0;
  max-width: 35%;
  position: relative;
  z-index: 4;
}

.chat-window .chat-cont-left .chat-header {
  align-items: center;
  background-color: #fff;
  border-bottom: 1px solid #e5e5e5;
  color: #324148;
  display: flex;
  height: 72px;
  justify-content: space-between;
  padding: 0 15px;
}

.chat-window .chat-cont-left .chat-header span {
  font-size: 20px;
  font-weight: 600;
  text-transform: capitalize;
}

.chat-window .chat-cont-left .chat-header .chat-compose {
  color: #8a8a8a;
  display: inline-flex;
}
.bg-gray {
    color: rgba(160,174,192);
    padding-bottom: 12px;
    font-size: 13px !important;
}
.chat-window .chat-cont-left .chat-search {
  background-color: #f5f5f6;
  border-bottom: 1px solid #e5e5e5;
  padding: 10px 15px;
  width: 100%;
}

.chat-window .chat-cont-left .chat-search .input-group {
  width: 100%;
}

.chat-window .chat-cont-left .chat-search .input-group .form-control {
  background-color: #fff;
  border-radius: 50px;
  padding-left: 36px;
}

.chat-window .chat-cont-left .chat-search .input-group .form-control:focus {
  border-color: #ccc;
  box-shadow: none;
}

.chat-window .chat-cont-left .chat-search .input-group .input-group-prepend {
  align-items: center;
  bottom: 0;
  color: #666;
  display: flex;
  left: 15px;
  pointer-events: none;
  position: absolute;
  top: 0;
  z-index: 4;
}

.chat-window .chat-scroll {
  max-height: calc(100vh - 255px);
  overflow-y: auto;
}

.chat-window .chat-cont-left .chat-users-list {
  background-color: #fff;
}

.chat-window .chat-cont-left .chat-users-list a.media {
  border-bottom: 1px solid #e5e5e5;
  padding: 10px 15px;
  transition: all 0.2s ease 0s;
}

.chat-window .chat-cont-left .chat-users-list a.media:last-child {
  border-bottom: 0;
}

.chat-window .chat-cont-left .chat-users-list a.media .media-img-wrap {
  margin-right: 15px;
  position: relative;
}

.chat-window .chat-cont-left .chat-users-list a.media .media-img-wrap .avatar {
  height: 45px;
  width: 45px;
}

.chat-window .chat-cont-left .chat-users-list a.media .media-img-wrap .status {
  bottom: 7px;
  height: 10px;
  right: 4px;
  position: absolute;
  width: 10px;
  border: 2px solid #fff;
}

.chat-window .chat-cont-left .chat-users-list a.media .media-body {
  display: flex;
  justify-content: space-between;
}

.chat-window .chat-cont-left .chat-users-list a.media .media-body > div:first-child .user-name, 
.chat-window .chat-cont-left .chat-users-list a.media .media-body > div:first-child .user-last-chat {
  max-width: 250px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.chat-window .chat-cont-left .chat-users-list a.media .media-body > div:first-child .user-name {
  color: #333;
  text-transform: capitalize;
}

.chat-window .chat-cont-left .chat-users-list a.media .media-body > div:first-child .user-last-chat {
  color: #8a8a8a;
  font-size: 14px;
  line-height: 24px;
}

.chat-window .chat-cont-left .chat-users-list a.media .media-body > div:last-child {
  text-align: right;
}

.chat-window .chat-cont-left .chat-users-list a.media .media-body > div:last-child .last-chat-time {
  color: #8a8a8a;
  font-size: 13px;
}

.chat-window .chat-cont-left .chat-users-list a.media:hover {
  background-color: #f5f5f6;
}

.chat-window .chat-cont-left .chat-users-list a.media.read-chat .media-body > div:last-child .last-chat-time {
  color: #8a8a8a;
}

.chat-window .chat-cont-left .chat-users-list a.media.active {
  background-color: #f5f5f6;
}

.chat-window .chat-cont-right {
  flex: 0 0 65%;
  max-width: 65%;
}

.chat-window .chat-cont-right .chat-header {
  align-items: center;
  background-color: #fff;
  border-bottom: 1px solid #e5e5e5;
  display: flex;
  height: 72px;
  justify-content: space-between;
  padding: 0 15px;
}

.chat-window .chat-cont-right .chat-header .back-user-list {
  display: none;
  margin-right: 5px;
  margin-left: -7px;
}

.chat-window .chat-cont-right .chat-header .media {
  align-items: center;
}

.chat-window .chat-cont-right .chat-header .media .media-img-wrap {
  position: relative;
  display: flex;
  align-items: center;
  margin-right: 15px;
}

.chat-window .chat-cont-right .chat-header .media .media-img-wrap .avatar {
  height: 50px;
  width: 50px;
}

.chat-window .chat-cont-right .chat-header .media .media-img-wrap .status {
  border: 2px solid #fff;
  bottom: 0;
  height: 10px;
  position: absolute;
  right: 3px;
  width: 10px;
}

.chat-window .chat-cont-right .chat-header .media .media-body .user-name {
  color: #333;
  font-size: 16px;
  font-weight: 600;
  text-transform: capitalize;
}

.chat-window .chat-cont-right .chat-header .media .media-body .user-status {
  color: #666;
  font-size: 14px;
}

.chat-window .chat-cont-right .chat-header .chat-options {
  display: flex;
}

.chat-window .chat-cont-right .chat-header .chat-options > a {
  align-items: center;
  border-radius: 50%;
  color: #8a8a8a;
  display: inline-flex;
  height: 30px;
  justify-content: center;
  margin-left: 10px;
  width: 30px;
}

.chat-window .chat-cont-right .chat-body {
  background-color: #f5f5f6;
}

.chat-window .chat-cont-right .chat-body ul.list-unstyled {
  margin: 0 auto;
  padding: 15px;
  width: 100%;
}

.chat-window .chat-cont-right .chat-body .media .avatar {
  height: 30px;
  width: 30px;
}

.chat-window .chat-cont-right .chat-body .media .media-body {
  margin-left: 20px;
}

.chat-window .chat-cont-right .chat-body .media .media-body .msg-box > div {
  padding: 10px 15px;
  border-radius: .25rem;
  display: inline-block;
  position: relative;
}

.chat-window .chat-cont-right .chat-body .media .media-body .msg-box > div p {
  color: #333;
  margin-bottom: 0;
}

.chat-window .chat-cont-right .chat-body .media .media-body .msg-box + .msg-box {
  margin-top: 5px;
}

.chat-window .chat-cont-right .chat-body .media.received {
  margin-bottom: 20px;
}

.chat-window .chat-cont-right .chat-body .media:last-child {
  margin-bottom: 0;
}

.chat-window .chat-cont-right .chat-body .media.received .media-body .msg-box > div {
  background-color: #fff;
}

.chat-window .chat-cont-right .chat-body .media.sent {
  margin-bottom: 20px;
}

.chat-window .chat-cont-right .chat-body .media.sent .media-body {
  align-items: flex-end;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  margin-left: 0;
}

.chat-window .chat-cont-right .chat-body .media.sent .media-body .msg-box > div {
  background-color: #e3e3e3;
}

.chat-window .chat-cont-right .chat-body .media.sent .media-body .msg-box > div p {
  color: #333;
}

.chat-window .chat-cont-right .chat-body .chat-date {
  font-size: 14px;
  margin: 1.875rem 0;
  overflow: hidden;
  position: relative;
  text-align: center;
  text-transform: capitalize;
}

.chat-window .chat-cont-right .chat-body .chat-date:before {
  background-color: #e0e3e4;
  content: "";
  height: 1px;
  margin-right: 28px;
  position: absolute;
  right: 50%;
  top: 50%;
  width: 100%;
}

.chat-window .chat-cont-right .chat-body .chat-date:after {
  background-color: #e0e3e4;
  content: "";
  height: 1px;
  left: 50%;
  margin-left: 28px;
  position: absolute;
  top: 50%;
  width: 100%;
}

.chat-window .chat-cont-right .chat-footer {
  background-color: #fff;
  border-top: 1px solid #e0e3e4;
  padding: 10px 15px;
  position: relative;
}

.chat-window .chat-cont-right .chat-footer .input-group {
  width: 100%;
}

.chat-window .chat-cont-right .chat-footer .input-group .form-control {
  background-color: #f5f5f6;
  border: none;
  border-radius: 50px;
}

.chat-window .chat-cont-right .chat-footer .input-group .form-control:focus {
  background-color: #f5f5f6;
  border: none;
  box-shadow: none;
}

.chat-window .chat-cont-right .chat-footer .input-group .input-group-prepend .btn, 
.chat-window .chat-cont-right .chat-footer .input-group .input-group-append .btn {
  background-color: transparent;
  border: none;
  color: #9f9f9f;
}

.chat-window .chat-cont-right .chat-footer .input-group .input-group-append .btn.msg-send-btn {
  background-color: #7638ff;
  border-color: #7638ff;
  border-radius: 50%;
  color: #fff;
  margin-left: 10px;
}

.msg-typing {
  width: auto;
  height: 24px;
  padding-top: 8px
}

.msg-typing span {
  height: 8px;
  width: 8px;
  float: left;
  margin: 0 1px;
  background-color: #a0a0a0;
  display: block;
  border-radius: 50%;
  opacity: .4
}

.msg-typing span:nth-of-type(1) {
  -webkit-animation: 1s blink infinite .33333s;
  animation: 1s blink infinite .33333s
}

.msg-typing span:nth-of-type(2) {
  -webkit-animation: 1s blink infinite .66666s;
  animation: 1s blink infinite .66666s
}

.msg-typing span:nth-of-type(3) {
  -webkit-animation: 1s blink infinite .99999s;
  animation: 1s blink infinite .99999s
}

.chat-window .chat-cont-right .chat-body .media.received .media-body .msg-box {
  position: relative;
}

.chat-window .chat-cont-right .chat-body .media.received .media-body .msg-box:first-child:before {
  border-bottom: 6px solid transparent;
  border-right: 6px solid #fff;
  border-top: 6px solid transparent;
  content: "";
  height: 0;
  left: -6px;
  position: absolute;
  right: auto;
  top: 8px;
  width: 0;
}

.chat-window .chat-cont-right .chat-body .media.sent .media-body .msg-box {
  padding-left: 50px;
  position: relative;
}

.chat-window .chat-cont-right .chat-body .media.sent .media-body .msg-box:first-child:before {
  border-bottom: 6px solid transparent;
  border-left: 6px solid #e3e3e3;
  border-top: 6px solid transparent;
  content: "";
  height: 0;
  left: auto;
  position: absolute;
  right: -6px;
  top: 8px;
  width: 0;
}

.chat-msg-info {
  align-items: center;
  display: flex;
  clear: both;
  flex-wrap: wrap;
  list-style: none;
  padding: 0;
  margin: 5px 0 0;
}

.chat-msg-info li {
  font-size: 13px;
  padding-right: 16px;
  position: relative;
}

.chat-msg-info li:not(:last-child):after {
  position: absolute;
  right: 8px;
  top: 50%;
  content: '';
  height: 4px;
  width: 4px;
  background: #d2dde9;
  border-radius: 50%;
  transform: translate(50%, -50%)
}

.chat-window .chat-cont-right .chat-body .media.sent .media-body .msg-box .chat-msg-info li:not(:last-child)::after {
  right: auto;
  left: 8px;
  transform: translate(-50%, -50%);
  background: #aaa;
}

.chat-window .chat-cont-right .chat-body .media.received .media-body .msg-box > div .chat-time {
  color: rgba(50, 65, 72, 0.4);
}

.chat-window .chat-cont-right .chat-body .media.sent .media-body .msg-box > div .chat-time {
  color: rgba(50, 65, 72, 0.4);
}

.chat-msg-info li a {
  color: #777;
}

.chat-msg-info li a:hover {
  color: #2c80ff
}

.chat-seen i {
  color: #00d285;
  font-size: 16px;
}

.chat-msg-attachments {
  padding: 4px 0;
  display: flex;
  width: 100%;
  margin: 0 -1px
}

.chat-msg-attachments > div {
  margin: 0 1px
}

.chat-window .chat-cont-right .chat-body .media.sent .media-body .msg-box > div .chat-msg-info {
  flex-direction: row-reverse;
}

.chat-window .chat-cont-right .chat-body .media.sent .media-body .msg-box > div .chat-msg-attachments {
  flex-direction: row-reverse
}

.chat-window .chat-cont-right .chat-body .media.sent .media-body .msg-box > div .chat-msg-info li {
  padding-left: 16px;
  padding-right: 0;
  position: relative;
}

.chat-attachment img {
  max-width: 100%;
}

.chat-attachment {
  position: relative;
  max-width: 130px;
  overflow: hidden;
}

.chat-attachment {
  border-radius: .25rem;
}

.chat-attachment:before {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: #000;
  content: "";
  opacity: 0.4;
  transition: all .4s;
}

.chat-attachment:hover:before {
  opacity: 0.6;
}

.chat-attach-caption {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  color: #fff;
  padding: 7px 15px;
  font-size: 13px;
  opacity: 1;
  transition: all .4s;
}

.chat-attach-download {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  opacity: 0;
  transition: all .4s;
  color: #fff;
  width: 32px;
  line-height: 32px;
  background: rgba(255, 255, 255, 0.2);
  text-align: center;
}

.chat-attach-download:hover {
  color: #495463;
  background: #fff;
}

.chat-attachment:hover .chat-attach-caption {
  opacity: 0;
}

.chat-attachment:hover .chat-attach-download {
  opacity: 1;
}

.chat-attachment-list {
  display: flex;
  margin: -5px;
}

.chat-attachment-list li {
  width: 33.33%;
  padding: 5px;
}

.chat-attachment-item {
  border: 5px solid rgba(230, 239, 251, 0.5);
  height: 100%;
  min-height: 60px;
  text-align: center;
  font-size: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.chat-window .chat-cont-right .chat-body .media.sent .media-body .msg-box > div:hover .chat-msg-actions {
  opacity: 1;
}

.chat-msg-actions {
  position: absolute;
  left: -30px;
  top: 50%;
  transform: translateY(-50%);
  opacity: 0;
  transition: all .4s;
  z-index: 2;
}

.chat-msg-actions > a {
  padding: 0 10px;
  color: #495463;
  font-size: 24px;
}

.chat-msg-actions > a:hover {
  color: #2c80ff;
}

@-webkit-keyframes blink {
  50% {
    opacity: 1
  }
}

@keyframes blink {
  50% {
    opacity: 1
  }
}

.btn-file {
  align-items: center;
  display: inline-flex;
  font-size: 20px;
  justify-content: center;
  overflow: hidden;
  padding: 0 0.75rem;
  position: relative;
  vertical-align: middle;
}

.btn-file input {
  cursor: pointer;
  filter: alpha(opacity=0);
  font-size: 23px;
  height: 100%;
  margin: 0;
  opacity: 0;
  position: absolute;
  right: 0;
  top: 0;
  width: 100%;
}

/*-----------------
  11. Search
-----------------------*/

.top-nav-search {
  float: left;
 /* margin-left: 15px;*/
}

.top-nav-search form {
  margin-top: 10px;
  position: relative;
  width: 230px;
}

.top-nav-search .search-form-inner {
  margin-top: 10px;
  position: relative;
  width: 230px;
}

.top-nav-search .form-control {
  border: 1px solid #e5e5e5;
  border-radius: .5rem;
  color: #333;
  height: 40px;
 /* padding: 10px 50px 10px 35px;*/
  box-shadow: rgb(209 243 255 / 33%) 0px 0.2px 3px 0.1px;
  margin-top: -40px;
    position: relative;
    width: 230px;
}

.top-nav-search .btn {
  background-color: transparent;
    border-color: transparent;
    color: #333;
    padding: 7px 15px;
    position: absolute;
    right: -45px;
    top: -40px;
}

.top-nav-search.active form {
  display: block;
  left: 0;
  position: absolute;
}

/*-----------------
  12. Sidebar
-----------------------*/

.sidebar {
  background-color: #fff;
  bottom: 0;
  left: 0;
  margin-top: 0;
  position: fixed;
  top: 90px;
  transition: all 0.2s ease-in-out 0s;
  width: 240px;
  z-index: 1001;
  border-top-right-radius: 20px;
  box-shadow: 0 0 13px 0 rgba(82,63,105,0.05);
  overflow-y: hidden;
}

.sidebar.opened {
  transition: all 0.4s ease;
}

.sidebar-inner {
  height: 100%;
  min-height: 100%;
  transition: all 0.2s ease-in-out 0s;
}

.sidebar-menu ul {
  font-size: 15px;
  list-style-type: none;
  margin: 0;
  padding: 15px 0;
  position: relative;
}

.sidebar-menu li a {
  color: #555a64;
  display: block;
  font-size: 15px;
  height: auto;
  padding: 0 20px;
}

.sidebar-menu li a:hover {
  color: #7638ff;
}

.sidebar-menu > ul > li > a:hover {
  background-color: rgba(118, 56, 255, 0.05);
  color: #7638ff;
}

.sidebar-menu li.active > a {
  background-color: rgba(118, 56, 255, 0.05);
  color: #7638ff;
  position: relative;
}

.sidebar-menu li.active > a::before {
  width: 5px;
  content: "";
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  background: #7638ff;
  transition: all 0.5s;
}

.menu-title {
  color: #9e9e9e;
  display: flex;
  font-size: 14px;
  opacity: 1;
  padding: 5px 15px;
  white-space: nowrap;
}

.menu-title > i {
  float: right;
  line-height: 40px;
}

.sidebar-menu li.menu-title a {
  color: #ff9b44;
  display: inline-block;
  margin-left: auto;
  padding: 0;
}

.sidebar-menu li.menu-title a.btn {
  color: #fff;
  display: block;
  float: none;
  font-size: 15px;
  margin-bottom: 15px;
  padding: 10px 15px;
}

.sidebar-menu ul ul a.active {
  color: #7638ff;
}

.mobile_btn {
  display: none;
  float: left;
}

.sidebar .sidebar-menu > ul > li > a span {
  transition: all 0.2s ease-in-out 0s;
  display: inline-block;
  margin-left: 10px;
  white-space: nowrap;
}

.sidebar .sidebar-menu > ul > li > a span.chat-user {
  margin-left: 0;
  overflow: hidden;
  text-overflow: ellipsis;
}

.sidebar .sidebar-menu > ul > li > a span.badge {
  margin-left: auto;
}

.sidebar-menu ul ul a {
  display: block;
  font-size: 15px;
  padding: 7px 10px 7px 45px;
  position: relative;
}

.sidebar-menu ul ul a span {
  float: right;
  color: #FF9800;
}

.sidebar-menu ul ul {
  display: none;
}

.sidebar-menu ul ul ul a {
  padding-left: 65px;
}

.sidebar-menu ul ul ul ul a {
  padding-left: 85px;
}

.sidebar-menu > ul > li {
  margin-bottom: 3px;
  position: relative;
}

.sidebar-menu > ul > li:last-child {
  margin-bottom: 25px;
}

.sidebar-menu .menu-arrow {
  transition: transform .15s;
  position: absolute;
  right: 15px;
  display: inline-block;
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
  text-rendering: auto;
  line-height: 40px;
  font-size: 16px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-transform: translate(0, 0);
  -transform: translate(0, 0);
  transform: translate(0, 0);
  line-height: 18px;
  top: 11px;
}

.sidebar-menu .menu-arrow:before {
  content: "\f105";
}

.sidebar-menu li a.subdrop .menu-arrow {
  -transform: rotate(90deg);
  transform: rotate(90deg);
}

.sidebar-menu ul ul a .menu-arrow {
  top: 10px;
}

.sidebar-menu > ul > li > a {
  align-items: center;
  display: flex;
  justify-content: flex-start;
  padding: 8px 15px;
  position: relative;
  transition: all 0.2s ease-in-out 0s;
}

.sidebar-menu > ul > li > a svg {
  width: 18px;
}

.sidebar-menu ul li a i {
  display: inline-block;
  font-size: 16px;
  line-height: 24px;
  text-align: left;
  vertical-align: middle;
  width: 20px;
  transition: all 0.2s ease-in-out 0s;
}

.sidebar-menu ul li.menu-title a i {
  font-size: 16px !important;
  margin-right: 0;
  text-align: right;
  width: auto;
}

.sidebar-menu li a > .badge {
  color: #fff;
}

/*-----------------
  13. Calendar
-----------------------*/

.calendar-events {
  border: 1px solid transparent;
  cursor: move;
  padding: 10px 15px;
}

.calendar-events:hover {
  border-color: #e9e9e9;
  background-color: #fff;
}

.calendar-events i {
  margin-right: 8px;
}

.calendar {
  float: left;
  margin-bottom: 0;
}

.fc-toolbar.fc-header-toolbar {
  margin-bottom: 1.5rem;
}

.none-border .modal-footer {
  border-top: none;
}

.fc-toolbar h2 {
  font-size: 18px;
  font-weight: 600;
  font-family: 'Roboto', sans-serif;
  line-height: 30px;
  text-transform: uppercase;
}
.sampleDiv {
  display: none;
}
.fc-day-grid-event .fc-time {
  font-family: 'Roboto', sans-serif;
}

.fc-day {
  background: #fff;
}

.fc-toolbar .fc-state-active,
.fc-toolbar .ui-state-active,
.fc-toolbar button:focus,
.fc-toolbar button:hover,
.fc-toolbar .ui-state-hover {
  z-index: 0;
}

.fc th.fc-widget-header {
  background: #eeeeee;
  font-size: 14px;
  line-height: 20px;
  padding: 10px 0;
  text-transform: uppercase;
}

.fc-unthemed th,
.fc-unthemed td,
.fc-unthemed thead,
.fc-unthemed tbody,
.fc-unthemed .fc-divider,
.fc-unthemed .fc-row,
.fc-unthemed .fc-popover {
  border-color: #f3f3f3;
}

.fc-basic-view .fc-day-number, 
.fc-basic-view .fc-week-number {
  padding: 2px 5px;
}

.fc-button {
  background: #f1f1f1;
  border: none;
  color: #797979;
  text-transform: capitalize;
  box-shadow: none !important;
  border-radius: 3px !important;
  margin: 0 3px !important;
  padding: 6px 12px !important;
  height: auto !important;
}

.fc-text-arrow {
  font-family: inherit;
  font-size: 16px;
}

.fc-state-hover {
  background: #f3f3f3;
}

.fc-state-highlight {
  background: #f0f0f0;
}

.fc-state-down,
.fc-state-active,
.fc-state-disabled {
  background-color: #7638ff !important;
  color: #fff !important;
  text-shadow: none !important;
}

.fc-cell-overlay {
  background: #f0f0f0;
}

.fc-unthemed .fc-today {
  background: #fff;
}

.fc-event {
  border-radius: 2px;
  border: none;
  color: #fff !important;
  cursor: move;
  font-size: 13px;
  margin: 1px 7px;
  padding: 5px 5px;
  text-align: center;
}

.fc-basic-view td.fc-week-number span {
  padding-right: 8px;
  font-weight: 700;
  font-family: 'Roboto', sans-serif;
}

.fc-basic-view td.fc-day-number {
  padding-right: 8px;
  font-weight: 700;
  font-family: 'Roboto', sans-serif;
}

.event-form .input-group .form-control {
  height: 40px;
}

.submit-section {
  text-align: center;
  margin-top: 40px;
}

.submit-btn {
  border-radius: 50px;
  font-size: 18px;
  font-weight: 600;
  min-width: 200px;
  padding: 10px 20px;
}

/*-----------------
  14. Inbox
-----------------------*/

.dropdown-action {
  margin-bottom: 5px;
}

.dropdown-action .dropdown-toggle:after {
  display: none;
}

.action-icon {
  color: #333;
  font-size: 18px;
  display: inline-block;
}

.table-inbox input[type="radio"],
.table-inbox input[type="checkbox"] {
  cursor: pointer;
}

.mail-list {
  list-style: none;
  padding: 0;
}

.mail-list > li > a {
  color: #333;
  display: block;
  padding: 10px;
}

.mail-list > li.active > a {
  color: #7638ff;
  font-weight: bold;
}

.unread .name,
.unread .subject,
.unread .mail-date {
  color: #000;
  font-weight: 600;
}

.table-inbox .starred.fas.fa-star {
  color: #ffd200;
}

.table.table-inbox > tbody > tr > td,
.table.table-inbox > tbody > tr > th,
.table.table-inbox > tfoot > tr > td,
.table.table-inbox > tfoot > tr > th,
.table.table-inbox > thead > tr > td,
.table.table-inbox > thead > tr > th {
  border-bottom: 1px solid #f2f2f2;
  border-top: 0;
}

.table-inbox {
  font-size: 15px;
  margin-bottom: 0;
}

.table.table-inbox thead {
  background-color: #fff;
}

.note-editor.note-frame {
  border: 1px solid #ddd;
  box-shadow: inherit;
}

.note-editor.note-frame .note-statusbar {
  background-color: #fff;
}

.note-editor.note-frame.fullscreen {
  top: 60px;
}

.note-editor.note-frame .btn-light {
  background-color: #f9f9f9;
  box-shadow: unset;
  color: #333;
}

.mail-title {
  font-weight: bold;
  text-transform: uppercase;
}

.form-control.search-message {
  border-color: #ccc;
  border-radius: 4px;
  height: 38px;
  width: 180px;
}

.table-inbox tr {
  cursor: pointer;
}

table.table-inbox tbody tr.checked {
  background-color: #ffffcc;
}

.mail-label {
  font-size: 16px !important;
  margin-right: 5px;
}

/*-----------------
  15. Mail View
-----------------------*/

.attachments {
  list-style: none;
  margin: 0;
  padding: 0;
}

.attachments li {
  border: 1px solid #eee;
  float: left;
  margin-bottom: 10px;
  margin-right: 10px;
  width: 180px;
}

.attach-info {
  background-color: #f4f4f4;
  padding: 10px;
}

.attach-file {
  color: #777;
  font-size: 70px;
  padding: 10px;
  min-height: 138px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.attach-file img {
  height: auto;
  max-width: 100%;
}

.mailview-header {
  border-bottom: 1px solid #ddd;
  margin-bottom: 20px;
  padding-bottom: 15px;
}

.mailview-footer {
  border-top: 1px solid #ddd;
  margin-top: 20px;
  padding-top: 15px;
}

.mailview-footer .btn-white {
  margin-top: 10px;
  min-width: 102px;
}

.sender-img {
  float: left;
  margin-right: 10px;
  width: 40px;
}

.sender-name {
  display: block;
}

.receiver-name {
  color: #777;
}

.mail-view-title {
  font-weight: 500;
  font-size: 24px;
  margin: 0;
}

.mail-view-action {
  float: right;
}

.mail-sent-time {
  float: right;
}

.inbox-menu {
  display: inline-block;
  margin: 0 0 1.875rem;
  padding: 0;
  width: 100%;
}

.inbox-menu li {
  display: inline-block;
  width: 100%;
}

.inbox-menu li + li {
  margin-top: 2px;
}

.inbox-menu li a {
  color: #333;
  display: inline-block;
  padding: 10px 15px;
  width: 100%;
  text-transform: capitalize;
  transition: 0.3s ease;
}

.inbox-menu li a i {
  font-size: 16px;
  padding-right: 10px;
  color: #878787;
}

.inbox-menu li a:hover, .inbox-menu li.active a, .inbox-menu li a:focus {
  background: rgba(33, 33, 33, 0.05);
}

.compose-btn {
  margin-bottom: 1.875rem;
}

.compose-btn a {
  font-weight: 600;
  padding: 8px 15px;
}

/*-----------------
  16. Error
-----------------------*/

.error-page {
  align-items: center;
  color: #1f1f1f;
  display: flex;
}

.error-page .main-wrapper {
  display: flex;
  flex-wrap: wrap;
  height: auto;
  justify-content: center;
  width: 100%;
  min-height: unset;
}

.error-box {
  margin: 0 auto;
  max-width: 480px;
  padding: 1.875rem 0;
  text-align: center;
  width: 100%;
}

.error-box h1 {
  color: #7638ff;
  font-size: 10em;
}

.error-box p {
  margin-bottom: 1.875rem;
}

.error-box .btn {
  border-radius: 50px;
  font-size: 18px;
  font-weight: 600;
  min-width: 200px;
  padding: 10px 20px;
}

/*-----------------
  17. Content
-----------------------*/

.main-wrapper {
  width: 100%;
  height: 100vh;
  min-height: 100vh;
}

.page-wrapper {
  margin-left: 240px;
  padding-top: 60px;
  position: relative;
  transition: all 0.4s ease;
}

.page-wrapper > .content {
  padding: 1.875rem 1.875rem 0;
}

.page-header {
  margin-bottom: 1.875rem;
}

.page-header .breadcrumb {
  background-color: transparent;
  color: #6c757d;
  font-size: 1rem;
  font-weight: 500;
  margin-bottom: 0;
  padding: 0;
}

.page-header .breadcrumb a {
  color: #333;
}

.page-title {
  color: #333;
  margin-bottom: 5px;
}

/*-----------------
  18. Login
-----------------------*/

.login-body {
  display: table;
  height: 100vh;
  min-height: 100vh;
}

.logo-dark{
  max-width: 180px;
  margin: auto;
  display: block;
}

.login-wrapper {
  width: 100%;
  height: 100%;
  display: table-cell;
  vertical-align: middle;
  padding-top: 1.875rem;
}

.login-wrapper .loginbox {
  background-color: #fff;
  border-radius: 20px;
  box-shadow: 0 0 13px 0 rgba(82,63,105,0.05);
  display: flex;
  margin: 1.875rem auto;
  max-width: 450px;
  width: 100%;
}

.login-wrapper .loginbox .login-left {
  align-items: center;
  background: linear-gradient(180deg, #8BC34A, #00bcd4);
  border-radius: 6px 0 0 6px;
  flex-direction: column;
  justify-content: center;
  padding: 80px;
  width: 450px;
  display: flex;
}

.login-wrapper .loginbox .login-right {
  align-items: center;
  display: flex;
  justify-content: center;
  padding: 2rem;
  width: 450px;
}

.login-wrapper .loginbox .login-right .login-right-wrap {
  max-width: 100%;
  flex: 0 0 100%;
}

.login-wrapper .loginbox .login-right h1 {
  font-size: 26px;
  font-weight: 500;
  margin-bottom: 5px;
  text-align: center;
}

.account-subtitle {
  color: #4c4c4c;
  font-size: 17px;
  margin-bottom: 1.875rem;
  text-align: center;
}

.login-wrapper .loginbox .login-right .forgotpass a {
  color: #a0a0a0;
}

.login-wrapper .loginbox .login-right .forgotpass a:hover {
  color: #333;
  text-decoration: underline;
}

.login-wrapper .loginbox .login-right .dont-have {
  color: #a0a0a0;
  margin-top: 1.875rem;
}

.login-wrapper .loginbox .login-right .dont-have a {
  color: #333;
}

.login-wrapper .loginbox .login-right .dont-have a:hover {
  text-decoration: underline;
}

.social-login {
  text-align: center;
}

.social-login > span {
  color: #757575;
  margin-right: 8px;
}

.social-login > a {
  background-color: #ccc;
  border-radius: 4px;
  color: #fff;
  display: inline-block;
  font-size: 18px;
  height: 32px;
  line-height: 32px;
  margin-right: 6px;
  text-align: center;
  width: 32px;
}

.social-login > a:last-child {
  margin-right: 0;
}

.social-login > a.facebook {
  background-color: #4b75bd;
}

.social-login > a.google {
  background-color: #fe5240;
}

.login-or {
  color: #757575;
  margin-bottom: 20px;
  margin-top: 20px;
  padding-bottom: 10px;
  padding-top: 10px;
  position: relative;
}

.or-line {
  background-color: #e5e5e5;
  height: 1px;
  margin-bottom: 0;
  margin-top: 0;
  display: block;
}

.span-or {
  background-color: #fff;
  display: block;
  left: 50%;
  margin-left: -20px;
  position: absolute;
  text-align: center;
  text-transform: uppercase;
  top: 0;
  width: 42px;
}

.lock-user {
  margin-bottom: 20px;
  text-align: center;
}

.lock-user img {
  margin-bottom: 15px;
  width: 100px;
}

.toggle-password {
  position: absolute;
  right: 17px;
  top: 50%;
  transform: translateY(-50%);
  color: #757575;
  cursor: pointer;
}

.toggle-password.fa-eye {
  margin-right: 1px;
}

.pass-group {
  position: relative;
}

/*-----------------
  19. Notifications
-----------------------*/

.notifications {
  padding: 0;
}

.notifications .notification-time {
  font-size: 12px;
  line-height: 1.35;
  color: #bdbdbd;
}

.notifications .media {
  margin-top: 0;
  border-bottom: 1px solid #f5f5f5;
}

.notifications .media:last-child {
  border-bottom: none;
}

.notifications .media a {
  display: block;
  padding: 10px 15px;
  border-radius: 2px;
}

.notifications .media a:hover {
  background-color: #fafafa;
}

.notifications .media > .avatar {
  margin-right: 10px;
}

.notifications .media-list .media-left {
  padding-right: 8px;
}

.topnav-dropdown-header {
  border-bottom: 1px solid #eee;
  text-align: center;
}

.topnav-dropdown-header,
.topnav-dropdown-footer {
  font-size: 14px;
  height: 40px;
  line-height: 40px;
  padding-left: 15px;
  padding-right: 15px;
}

.topnav-dropdown-footer {
  border-top: 1px solid #eee;
}

.topnav-dropdown-footer a {
  display: block;
  text-align: center;
  color: #333;
}

.display-none {
  display: none;
}

.user-menu.nav > li > a .badge {
  background-color: #ef3737;
  display: block;
  font-size: 10px;
  font-weight: bold;
  min-height: 15px;
  min-width: 15px;
  color: #fff;
  position: absolute;
  right: 3px;
  top: 6px;
}

.user-menu.nav > li > a > i {
  font-size: 1.5rem;
  line-height: 60px;
}

.user-menu.nav > li > a > svg {
  font-size: 1.5rem;
  line-height: 60px;
}

.noti-details {
  color: #989c9e;
  margin-bottom: 0;
}

.noti-title {
  color: #333;
}

.notifications .noti-content {
  height: 290px;
  width: 350px;
  overflow-y: auto;
  position: relative;
}

.notification-list {
  list-style: none;
  padding: 0;
  margin: 0;
}

.notifications ul.notification-list > li {
  margin-top: 0;
  border-bottom: 1px solid #f5f5f5;
}

.notifications ul.notification-list > li:last-child {
  border-bottom: none;
}

.notifications ul.notification-list > li a {
  display: block;
  padding: 10px 15px;
  border-radius: 2px;
}

.notifications ul.notification-list > li a:hover {
  background-color: #fafafa;
}

.notifications ul.notification-list > li .list-item {
  border: 0;
  padding: 0;
  position: relative;
}

.topnav-dropdown-header .notification-title {
  color: #333;
  display: block;
  float: left;
  font-size: 14px;
}

.topnav-dropdown-header .clear-noti {
  color: #f83f37;
  float: right;
  font-size: 12px;
  text-transform: uppercase;
}

.noti-time {
  margin: 0;
}

/*-----------------
  20. Dashboard
-----------------------*/

.dash-title {
  font-size: 15px;
  font-weight: 600;
}

.dash-widget-icon {
  align-items: center;
  border-radius: 10px;
  color: #fff;
  display: inline-flex;
  font-size: 1.875rem;
  height: 4rem;
  justify-content: center;
  text-align: center;
  width: 4rem;
}

.dash-count {
  font-size: 18px;
  margin-left: 15px;
  padding-top: 5px;
}

.dash-widget-info h3 {
  margin-bottom: 10px;
}

.dash-widget-header {
  align-items: center;
  display: flex;
}

.activity-feed {
  list-style: none;
  margin-bottom: 0;
  margin-left: 5px;
  padding: 0;
}

.activity-feed .feed-item {
  border-left: 2px solid #e4e8eb;
  padding-bottom: 19px;
  padding-left: 20px;
  position: relative;
}

.activity-feed .feed-item:last-child {
  border-color: transparent;
}

.activity-feed .feed-item:after {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  left: -7px;
  width: 12px;
  height: 12px;
  border-radius: 50%;
  background-color: #7638ff;
}

.activity-feed .feed-item .feed-date {
  display: block;
  position: relative;
  color: #777;
  text-transform: uppercase;
  font-size: 13px;
}

.activity-feed .feed-item .feed-text {
  color: #777;
  position: relative;
}

.activity-feed .feed-item .feed-text a {
  color: #333;
  font-weight: 600;
}

.modal-icon i {
  font-size: 42px;
  color: #6c757d;
}

.modal-footer {
  border-top: 0;
  padding-top: 0;
  padding-bottom: .75rem;
}

.add-remove i {
  font-size: 18px;
  cursor: pointer;
}

/*-----------------
  21. Invoices
-----------------------*/

.invoice-item .invoice-logo {
  margin-bottom: 30px;
}

.invoice-item .invoice-logo img {
  width: auto;
  max-height: 52px;
}

.invoice-item .invoice-text h2 {
  color:#272b41;
  font-size:36px;
  font-weight:600;
}

.invoice-item .invoice-details {
  text-align: right;
  font-weight: 500
}

.invoice-item .invoice-details strong {
  color:#272b41
}

.invoice-item .invoice-details-two {
  text-align:left
}

.invoice-item .invoice-text {
  padding-top:42px;
  padding-bottom:36px
}

.invoice-item .invoice-text h2 {
  font-weight:400
}

.invoice-info {
  margin-bottom: 30px;
}

.invoice-info p {
  margin-bottom: 0;
}

.invoice-info.invoice-info2 {
  text-align: right;
}

.invoice-item .customer-text {
  font-size: 18px;
  color: #272b41;
  font-weight: 600;
  margin-bottom: 8px;
  display: block
}

.invoice-table tr th,
.invoice-table tr td,
.invoice-table-two tr th,
.invoice-table-two tr td {
  color: #272b41;
  font-weight: 600;
  padding: 10px 20px;
  line-height: inherit
}

.invoice-table tr td,
.invoice-table-two tr td {
  font-weight: 500;
}

.invoice-table-two {
  margin-bottom:0
}

.invoice-table-two tr th,
.invoice-table-two tr td {
  border-top: 0;
}

.invoice-table-two tr td {
  text-align: right
}

.invoice-info h5 {
  font-size: 16px;
  font-weight: 500;
}

.other-info {
  margin-top: 10px;
}

.file {
  visibility: hidden;
  position: absolute;
}

.btn-group,
.btn-group-vertical {
  position: relative;
  display: -inline-flexbox;
  display: inline-flex;
  vertical-align: middle;
}

/*-----------------
  22. Settings
-----------------------*/

.settings-menu ul {
  display: block;
  padding: 0;
}

.settings-menu ul li + li {
  margin-top: 15px;
}

.settings-menu ul li a {
  color: #455560;
  padding: 0;
  border: 0 !important;
  display: inline-block;
}

.settings-menu ul li a:hover, .settings-menu ul li a:focus {
  background-color: transparent !important;
  border: 0 !important;
}

.settings-menu ul li a.active {
  color: #7638ff !important;
  border: 0;
}

.settings-menu ul li i {
  margin-right: 10px;
  font-size: 20px;
  min-width: 25px;
}

/*-----------------
  23. Switch Buttons
-----------------------*/

.toggle-switch {
  display: flex;
  align-items: center;
  cursor: pointer
}

.toggle-switch:not(.form-group) {
  margin-bottom: 0
}

.toggle-switch-input {
  position: absolute;
  z-index: -1;
  opacity: 0
}

.toggle-switch-content {
  flex: 1;
  margin-left: .5rem
}

.toggle-switch-label {
  position: relative;
  display: block;
  width: 3rem;
  height: 2rem;
  background-color: #e7eaf3;
  background-clip: content-box;
  border: .125rem solid transparent;
  border-radius: 6.1875rem;
  transition: .3s
}

.toggle-switch-indicator {
  position: absolute;
  left: .125rem;
  bottom: 50%;
  width: 1.5rem;
  height: 1.5rem;
  background-color: #fff;
  transform: initial;
  box-shadow: 0 3px 6px 0 rgba(140, 152, 164, .25);
  border-radius: 50%;
  transform: translate3d(0, 50%, 0);
  transition: .3s
}
.dashboard_chart  {
  width: 120px;
}
.toggle-switch-input:checked + .toggle-switch-label {
  background-color: #7638ff;
}

.toggle-switch-input:checked+.toggle-switch-label .toggle-switch-indicator {
  transform: translate3d(1.025rem, 50%, 0)
}

.toggle-switch-input.is-valid + .toggle-switch-label {
  background-color: #00c9a7
}

.toggle-switch-input.is-invalid + .toggle-switch-label {
  background-color: #ed4c78
}

.toggle-switch-input:disabled + .toggle-switch-label {
  background-color: rgba(231, 234, 243, .5)
}

.toggle-switch-input:checked:disabled + .toggle-switch-label {
  background-color: rgba(55, 125, 255, .5)
}

.toggle-switch-sm .toggle-switch-label {
  width: 2.5rem;
  height: 1.6125rem
}

.toggle-switch-sm .toggle-switch-indicator {
  width: 1.20938rem;
  height: 1.20938rem
}

.toggle-switch-sm .toggle-switch-input:checked + .toggle-switch-label .toggle-switch-indicator {
  transform: translate3d(.81094rem, 50%, 0)
}

/*-----------------
  24. Profile
-----------------------*/

.cal-icon {
  position: relative;
  width: 100%;
}
.datemx .mx-datepicker {
width: 100%;
}
.datemx .mx-input {
  height: calc(1.6em + 1.21875rem);
}

.cal-icon:after {
  color: #979797;
  content: "\f073";
  display: block;
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
  font-size: 15px;
  margin: auto;
  position: absolute;
  right: 15px;
  top: 10px;
}

.profile-cover {
  position: relative;
  padding: 1.75rem 2rem;
  border-radius: .75rem;
  height: 10rem;
}

.profile-cover-wrap {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  height: 10rem;
  background-color: #e7eaf3;
  border-radius: .75rem;
}

.profile-cover-img {
  width: 100%;
  height: 10rem;
  -o-object-fit: cover;
  object-fit: cover;
  vertical-align: top;
  border-radius: .75rem;
}

.cover-content {
  position: relative;
  z-index: 1;
  padding: 1rem 2rem;
  position: absolute;
  bottom: 0;
  right: 0;
}

.custom-file-btn {
  position: relative;
  overflow: hidden;
}

.custom-file-btn-input {
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
  width: 100%;
  height: 100%;
  opacity: 0;
}

.profile-cover-avatar {
  display: flex;
  margin: -6.3rem auto .5rem auto;
  border: 3px solid #fff;
  border-radius: 50%;
}

.profile-cover-avatar input[type="file"] {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  z-index: -1;
  opacity: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(19,33,68,.25);
  border-radius: 50%;
  transition: .2s;
}

.avatar-edit {
  position: absolute;
  bottom: 0;
  right: 0;
  cursor: pointer;
  border-radius: 50%;
  width: 36px;
  height: 36px;
  display: inline-flex;
  flex-shrink: 0;
  justify-content: center;
  align-items: center;
  color: #677788;
  background-color: #fff;
  border-radius: 50%;
  transition: .2s;
  box-shadow: 0 3px 6px 0 rgba(140,152,164,.25);
}

.avatar-edit svg {
  width: 18px;
}

.card-table div.table-responsive > div.dataTables_wrapper > div.row:first-child {
  padding: 1.5rem 1.5rem 0;
}

.card-table div.table-responsive > div.dataTables_wrapper > div.row:last-child {
  padding: 0 1.5rem 1.5rem;
}

#filter_inputs {
  display: none;
}

.input-label {
  display: block;
  color: #1e2022;
  font-size: .875rem;
}

.submit-section {
  text-align: center;
  margin-top: 40px;
}

.submit-btn {
  border-radius: 50px;
  font-size: 18px;
  font-weight: 600;
  min-width: 200px;
  padding: 10px 20px;
}

.card-body-height {
  height: 28rem;
  overflow: hidden;
  overflow-y: auto;
}

.profile-cover-avatar .avatar-img {
  display: block;
  max-width: 100%;
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
  pointer-events: none;
  border-radius: 50%;
}

  .wizardnew.vue-step-wizard {
    background-color: #f7f8fc;
    max-width: 1200px !important;
    margin: auto;
    padding: 40px;
    width: 1200px;
}
span.tabLabel {
    display: block;
    margin-top: 5px;
}
.wizardnew .step-pills .step-item {
    background-color: #f5f5f5;
    border-radius: 10px;
    padding: 5px 20px;
    list-style-type: none;
    padding: .5rem 1.5rem;
    text-align: center;
}
.permission {
  display: flex;
  justify-content: space-between;
  border: 1px solid #dee2e6!important;
  border-radius: 5px
}
.wizardnew .step-pills .step-item.active {
    border: 1px solid #621aff;
    background-color: #621aff;
}
.wizardnew .step-pills .step-item.active a {
    color: #fff;
}
.wizardnew .step-pills .step-item.active .tabStatus {
    color: #621aff;
    background: #fff;
}
/*-----------------
  25. Responsive
-----------------------*/

@media (min-width: 768px) {
  .avatar-xxl {
    width: 8rem;
    height: 8rem;
  }

  .avatar-xxl .border {
    border-width: 4px !important;
  }

  .avatar-xxl .rounded {
    border-radius: 12px !important;
  }

  .avatar-xxl .avatar-title {
    font-size: 42px;
  }

  .avatar-xxl.avatar-away:before,
  .avatar-xxl.avatar-offline:before,
  .avatar-xxl.avatar-online:before {
    border-width: 4px;
  }
}

@media (min-width: 992px) {
  #toggle_btn {
    align-items: center;
    color: #333;
    display: inline-flex;
    float: left;
    font-size: 30px;
    height: 60px;
    justify-content: center;
    margin-left: 15px;
    padding: 0 15px;
  }

  .mini-sidebar .header-left .logo img {
    height: auto;
    max-height: 40px;
    width: auto;
  }

  .mini-sidebar .header .header-left .logo {
    display: none;
  }

  .mini-sidebar .header-left .logo.logo-small {
    display: block;
  }

  .mini-sidebar .header .header-left {
    padding: 0 5px;
    width: 78px;
  }

  .mini-sidebar .sidebar {
    width: 78px;
  }

  .mini-sidebar.expand-menu .sidebar {
    width: 240px;
  }

  .mini-sidebar .menu-title {
    visibility: hidden;
    white-space: nowrap;
  }

  .mini-sidebar.expand-menu .menu-title {
    visibility: visible;
  }

  .mini-sidebar .menu-title a {
    visibility: hidden;
  }

  .mini-sidebar.expand-menu .menu-title a {
    visibility: visible;
  }

  .modal-open.mini-sidebar .sidebar {
    z-index: 1051;
  }

  .mini-sidebar .sidebar .sidebar-menu ul > li > a span {
    display: none;
    transition: all 0.2s ease-in-out;
    opacity: 0;
  }

  .mini-sidebar.expand-menu .sidebar .sidebar-menu ul > li > a span {
    display: inline;
    opacity: 1;
  }

  .mini-sidebar .page-wrapper {
    margin-left: 78px;
  }
}

@media (max-width: 991.98px) {
  .header .header-left {
    position: absolute;
    width: 100%;
  }
  
  .add-item {
    margin-top: 10px;
  }

  .flag-nav{
    display: none;
  }

  .mobile_btn {
    color: #333;
    cursor: pointer;
    display: block;
    font-size: 24px;
    height: 60px;
    left: 0;
    line-height: 60px;
    padding: 0 15px;
    position: absolute;
    text-align: center;
    top: 0;
    z-index: 10;
  }

  #toggle_btn {
    display: none;
  }

  .top-nav-search {
    display: none;
  }

  .login-wrapper .loginbox .login-left {
    padding: 80px 50px;
    width: 50%;
  }

  .login-wrapper .loginbox .login-right {
    padding: 50px;
    width: 50%;
  }

  .sidebar {
    margin-left: -225px;
    width: 225px;
    transition: all 0.4s ease;
    z-index: 1041;
  }

  .page-wrapper {
    margin-left: 0;
    padding-left: 0;
    padding-right: 0;
    transition: all 0.4s ease;
  }

  .chat-window .chat-scroll {
    max-height: calc(100vh - 255px);
  }

  .chat-window .chat-cont-left,
  .chat-window .chat-cont-right {
    flex: 0 0 100%;
    max-width: 100%;
    transition: left 0.3s ease-in-out 0s, right 0.3s ease-in-out 0s;
    width: 100%;
  }

  .chat-window .chat-cont-left {
    border-right: 0;
  }

  .chat-window .chat-cont-right {
    position: absolute;
    right: calc(-100% + -1.875rem);
    top: 0;
  }

  .chat-window .chat-cont-right .chat-header {
    justify-content: start;
  }

  .chat-window .chat-cont-right .chat-header .back-user-list {
    display: block;
  }

  .chat-window .chat-cont-right .chat-header .chat-options {
    margin-left: auto;
  }

  .chat-window.chat-slide .chat-cont-left {
    left: calc(-100% + -1.875rem);
  }

  .chat-window.chat-slide .chat-cont-right {
    right: 0;
  }
}

@media (max-width: 767.98px) {
  body {
    font-size: 0.9375rem;
  }

  h1,
  .h1 {
    font-size: 2rem;
  }

  h2,
  .h2 {
    font-size: 1.75rem;
  }

  h3,
  .h3 {
    font-size: 1.5rem;
  }

  h4,
  .h4 {
    font-size: 1.125rem;
  }

  h5,
  .h5 {
    font-size: 1rem;
  }

  h6,
  .h6 {
    font-size: 0.875rem;
  }

  .header .has-arrow .dropdown-toggle:after {
    display: none;
  }

  .user-menu.nav > li > a > span:not(.user-img) {
    display: none;
  }

  .navbar-nav .open .dropdown-menu {
    float: left;
    position: absolute;
  }

  .navbar-nav.user-menu .open .dropdown-menu {
    left: auto;
    right: 0;
  }

  .header .header-left {
    padding: 0 15px;
  }

  .header .header-left .logo {
    display: none;
  }

  .header-left .logo.logo-small {
    display: inline-block;
  }

  .login-wrapper .loginbox .login-left {
    display: none;
  }

  .login-wrapper .loginbox {
    max-width: 450px;
    min-height: unset;
  }

  .login-wrapper .loginbox .login-right {
    float: none;
    padding: 1.875rem;
    width: 100%;
  }

  .top-action-left .float-left {
    float: none !important;
  }

  .top-action-left .btn-group {
    margin-bottom: 15px;
  }

  .top-action-right {
    text-align: center;
  }

  .top-action-right a.btn.btn-white {
    margin-bottom: 15px;
  }

  .mail-sent-time {
    float: left;
    margin-top: 10px;
    width: 100%;
  }

  .login-wrapper .loginbox .login-right h1 {
    font-size: 22px;
  }

  .error-box h1 {
    font-size: 6em;
  }

  .error-box .btn {
    font-size: 15px;
    min-width: 150px;
    padding: 8px 20px;
  }

  .dash-count {
    font-size: 16px;
    display: inline-block;
  }

  .dash-widget-header{
    display: block;
  }

  .nav {
    display: block;
  }
  .datetype {
    width: 100%;
  }
}

@media (max-width: 575.98px) {
  .card {
    margin-bottom: 0.9375rem;
  }

  .page-wrapper > .content {
    padding: 0.9375rem 0.9375rem 0;
  }

  .chat-window {
    margin-bottom: 0.9375rem;
  }

  .card-body {
    padding: 1.25rem;
  }

  .card-header {
    padding: .75rem 1.25rem;
  }

  .card-footer {
    padding: .75rem 1.25rem;
  }

  .page-header {
    margin-bottom: 0.9375rem;
  }

  .pagination-lg .page-link {
    font-size: 1.2rem;
    padding: 0.5rem 0.625rem;
  }

  .invoice-details {
    float: left;
    text-align: left;
  }

  .fc-toolbar .fc-right {
    display: inline-block;
    float: none;
    margin: 10px auto 0;
    width: 200px;
    clear: both;
  }

  .fc-toolbar .fc-left {
    float: none;
    margin: 0 auto;
    width: 200px;
  }

  .fc-toolbar .fc-center {
    display: inline-block;
    width: 100%;
    text-align: center;
  }

  .fc-toolbar .fc-center h2 {
    width: 100%;
  }

  .chat-window .chat-cont-right .chat-header .media .media-body {
    display: none;
  }

  .chat-window .chat-cont-left .chat-users-list a.media .media-body > div:first-child .user-name,
  .chat-window .chat-cont-left .chat-users-list a.media .media-body > div:first-child .user-last-chat {
    max-width: 160px;
  }

  .page-header .breadcrumb {
    display: none;
  }
}

.vs__dropdown-toggle {
  height: 43px !important ;
}

.avatar-img2 #pick-avatar {
  width: 8rem;
  height: 8rem;
  display: flex;
  padding: 0 !important;
  border: 3px solid #fff;
  border-radius: 50%;
}

.avatar-img2 #pick-avatar img {
  display: block;
  max-width: 100%;
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
  pointer-events: none;
  border-radius: 50%;
}

.table-pagination {
  justify-content: flex-end;
  padding: 0 1.5rem 1.5rem;
}

.sent_button {
  border-color: #7638ff;
}

.sent_button:hover {
  background-color: #7638ff;
  color: #fff;
}

.sales_value {
  display: inline-block;
  width: 100%;
}

.basic_info {
  color: lightgray !important;
  font-weight: 800;
}

.search_icon {
  margin-top: -33px;
  position: absolute;
  width: 230px;
  border: 1px solid #e5e5e5;
  border-radius: .5rem;
  color: #333;
  height: 40px;
  padding: 10px 50px 10px 15px;
  box-shadow: rgb(209 243 255 / 33%) 0px 0.2px 3px;
}

.search {
  width: 100%;
  transform: none !important;
  margin-top: 40px !important;
  background-color: #fff;
}

#sw_items {
  width: 100% !important;
  margin-top: 40px !important;
  transform: none !important;
  background-color: #fff;
}

/**Dropzone**/

.dropzone-wrapper {
  border: 2px dashed #91b0b3;
  color: #92b0b3;
  position: relative;
  height: 150px;

}

.dropzone-desc {
  position: absolute;
  margin: 0 auto;
  left: 0;
  right: 0;
  text-align: center;
  width: 40%;
  top: 50px;
  font-size: 16px;
}

.dropzone,
  .dropzone:focus {
  position: absolute;
  outline: none !important;
  width: 100%;
  height: 150px;
  cursor: pointer;
  opacity: 0;
}

.dropzone-wrapper:hover,
  .dropzone-wrapper.dragover {
  background: #ecf0f5;
}

.box {
  position: relative;
  background: #ffffff;
  width: 100%;
}

.box-header {
  color: #444;
  display: block;
  padding: 10px;
  position: relative;
  border-bottom: 1px solid #f4f4f4;
  margin-bottom: 10px;
}

.box-tools {
  position: absolute;
  right: 10px;
  top: 5px;
}

.img-avatar {
  border: 2px dashed #91b0b3;
  color: #92b0b3;
  position: relative;
  height: 150px;
  text-align: center;
}
.taxselect {
  display: contents;
  line-height: 25px;
}
.floatright {
  float: right !important
}
/*# sourceMappingURL=style.css.map*/

/*# sourceMappingURL=style.css.map*/
@media only screen and (max-width: 767.98px) {
  .double_icon svg  {
    display: none;
  }
  .double_icon .dropdown-menu.show {
    padding: 5px !important;
}
.double_icon .dropdown-menu {
    font-size: 12px;
}
  .floatright {
    float: none !important
  }
  .wizardnew.vue-step-wizard {
    max-width: 100% !important;
    width: 100%;
}
.step-pills {
  flex-direction: column;
}
.step-pills li {
  margin-bottom: 10px;
}
}

.pointer-events {
  pointer-events: none;
}
  .redcolor {
    color: #ef3737
  }
  .paymentsempty {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    -webkit-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
  }
 

  .report-title {
    font-size: 1.5rem;
    text-decoration: underline;
    text-align: center;
    margin-bottom: 1.5rem;
  }
  .report-table {
      border-bottom: 1px solid rgba(0, 0, 0, 0.05) !important;
      margin-bottom: 20px;
  }
  .report-table th, .report-table td {
    border: 0 !important;
  }
  .report-table td {
    color: #777;
  }
  .report-table td.report-total {
    color: #333;
  }
  .table.report-table > tbody > tr > th, .table.report-table > tbody > tr > td {
    padding: 0 7px 10px;
  }
  .table.report-table tbody + tbody {
    border-top: 0;
  }
  .total-report-table tr th,
  .total-report-table tr td {
    color: #272b41;
    font-weight: 600;
      font-size: 20px;
    line-height: inherit
  }
  .total-report-table tr td {
      font-weight: bold;
      text-align: right;
      color: #7638ff;
  }
  .total-report-table tr th,
  .total-report-table tr td {
    border-top: 0;
  }
  .table.total-report-table > tbody > tr > th, .table.total-report-table > tbody > tr > td {
    padding: 0;
  }
  .step-footer {
    display: none;
  }
  .step-progress {
    display: none;
  }

